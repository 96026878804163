*, :after, :before, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :after, :before {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:after, :before {
  --tw-content: "";
}

:host, html {
  -webkit-text-size-adjust: 100%;
  font-feature-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-variation-settings: normal;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  height: 0;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, pre, samp {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  color: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre, fieldset {
  margin: 0;
}

fieldset, legend {
  padding: 0;
}

menu, ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  color: #9ca3af;
  opacity: 1;
}

[role="button"], button {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

audio, canvas, embed, iframe, img, object, svg, video {
  vertical-align: middle;
  display: block;
}

img, video {
  height: auto;
  max-width: 100%;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.bottom-0 {
  bottom: 0;
}

.left-1\/2 {
  left: 50%;
}

.isolate {
  isolation: isolate;
}

.z-50 {
  z-index: 50;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-px {
  margin-bottom: 1px;
}

.ml-8 {
  margin-left: 2rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-full {
  height: 100%;
}

.w-3\/4 {
  width: 75%;
}

.w-full {
  width: 100%;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.table-auto {
  table-layout: auto;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
}

.-translate-x-1\/2, .rotate-180 {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
}

.scale-0 {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
}

.scale-0, .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.list-decimal {
  list-style-type: decimal;
}

.list-disc {
  list-style-type: disc;
}

.list-none {
  list-style-type: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.self-center {
  align-self: center;
}

.overflow-x-auto {
  overflow-x: auto;
}

.rounded {
  border-radius: .25rem;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l-8 {
  border-left-width: 8px;
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
}

.bg-contrast-color {
  background-color: var(--contrast-color);
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.bg-inverse-color {
  background-color: var(--inverse-color);
}

.bg-menu-bg-color {
  background-color: var(--menu-background);
}

.bg-teal-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(45 212 191 / var(--tw-bg-opacity, 1));
}

.bg-translation-bg-color {
  background-color: var(--translation-background);
}

.fill-current {
  fill: currentColor;
}

.p-1 {
  padding: .25rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-px {
  padding-bottom: 1px;
}

.pr-8 {
  padding-right: 2rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.align-text-top {
  vertical-align: text-top;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.leading-normal {
  line-height: 1.5;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity, 1));
}

.text-default-color {
  color: var(--default-color);
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity, 1));
}

.text-teal-500 {
  --tw-text-opacity: 1;
  color: rgb(20 184 166 / var(--tw-text-opacity, 1));
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
}

.shadow, .shadow-inner {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.menu__link {
  color: var(--menu-link);
}

.pagination__link {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity, 1));
}

.dark-mode-label {
  --tw-text-opacity: 1;
  color: rgb(20 184 166 / var(--tw-text-opacity, 1));
}

article .header__title__link {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(45 212 191 / var(--tw-bg-opacity, 1));
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
  padding: .25rem;
}

article .article__meta__link {
  color: rgb(13 148 136 / var(--tw-text-opacity, 1));
}

h1 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1;
}

h1:not(:first-child) {
  padding-top: 3rem;
}

h2 {
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 2.25rem;
}

h2:not(:first-child) {
  padding-top: 2.5rem;
}

h3 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
}

h3:not(:first-child), h4:not(:first-child) {
  padding-top: 2rem;
}

h4 {
  font-size: 1.25rem;
}

h4, h5 {
  font-weight: 600;
  line-height: 1.75rem;
}

h5 {
  font-size: 1.125rem;
}

h5, h6 {
  padding-top: 2rem;
}

h6 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

:root {
  --primary-color: #14b8a6;
  --default-color: #000;
  --inverse-color: #f3f4f6;
  --menu-background: #fde68a;
  --menu-link: #2563eb;
  --translation-background: #9ca3af;
  --contrast-color: #fff;
  --shadow: #6b7280;
  --drop-shadow: #6b7280;
}

#dark-mode:checked ~ .theme {
  --primary-color: #14b8a6;
  --default-color: #d1d5db;
  --inverse-color: #000;
  --menu-background: #312e81;
  --menu-link: #22d3ee;
  --translation-background: #1f2937;
  --contrast-color: #111827;
  --shadow: #6b7280;
  --drop-shadow: #6b7280;
}

.theme {
  background-color: var(--contrast-color);
  color: var(--default-color);
}

.layout {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1280px;
  min-height: 100vh;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1.125rem;
  line-height: 1.5;
  display: flex;
}

.cover {
  text-align: center;
  width: 100%;
  margin-bottom: 3rem;
}

.cover__title {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.menu {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  --tw-shadow-color: var(--shadow);
  --tw-shadow: var(--tw-shadow-colored);
  background-color: var(--inverse-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  height: 100%;
  opacity: 0;
  text-align: center;
  visibility: hidden;
  width: 100%;
  flex-direction: column;
  row-gap: 1.5rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  display: none;
  position: fixed;
}

.menu ul {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 1.5rem;
  display: grid;
}

.menu ul li.active {
  --tw-text-opacity: 1;
  color: rgb(20 184 166 / var(--tw-text-opacity, 1));
}

.menu__link {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}

.content {
  background-color: var(--inverse-color);
  width: 100%;
  border-radius: .25rem;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.pagination {
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pagination, .tags {
  flex-wrap: wrap;
  display: flex;
}

.tags {
  justify-content: flex-start;
}

.pagination li, .tags li {
  margin: 0;
  list-style-type: none;
}

.tags li {
  margin-right: .75rem;
}

.translations {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  background-color: var(--translation-background);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.langs-list {
  column-gap: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  display: inline-flex;
}

.dark-mode-label {
  fill: currentColor;
  color: var(--default-color);
  cursor: pointer;
  vertical-align: text-top;
  display: inline-block;
}

#cool-menu, #dark-mode {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  position: fixed;
}

#cool-menu + label > svg, #cool-menu:checked + label > svg {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

#cool-menu:checked + label > svg {
  --tw-rotate: 180deg;
  --tw-text-opacity: 1;
  color: rgb(20 184 166 / var(--tw-text-opacity, 1));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#cool-menu:checked ~ .menu {
  opacity: 1;
  visibility: visible;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cool-menu {
  fill: currentColor;
  --tw-shadow-color: hsl(var(--drop-shadow));
  --tw-shadow: var(--tw-shadow-colored);
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  color: var(--default-color);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.cool-menu__label {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  z-index: 50;
  margin-bottom: 1rem;
  position: fixed;
  bottom: 0;
  left: 50%;
}

@media (min-width: 1024px) {
  .menu {
    background-color: var(--menu-background);
    opacity: 1;
    text-align: left;
    visibility: visible;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    row-gap: 0;
    display: flex;
    position: relative;
  }

  .menu ul {
    flex-wrap: wrap;
    align-content: center;
    padding-left: 2rem;
  }

  .menu ul, .menu ul li {
    padding-right: 2rem;
    display: flex;
  }

  .menu ul li {
    align-self: center;
  }

  .content {
    --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    --tw-shadow-color: var(--shadow);
    --tw-shadow: var(--tw-shadow-colored);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    width: 75%;
  }

  .cool-menu {
    display: none;
  }

  .dark-mode-label {
    align-self: center;
    padding-right: 2rem;
  }
}

article {
  margin-bottom: 2rem;
}

article .header__title {
  letter-spacing: .025em;
  margin-bottom: .75rem;
}

article .article__meta {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
  margin-top: 1rem;
}

article section > p:first-of-type {
  margin-top: 1rem;
}

section {
  overflow-wrap: break-word;
}

pre.code, pre.doctest-block, pre.literal-block, pre.math, section pre {
  margin-bottom: 2rem;
  margin-left: 0;
  margin-right: 0;
  overflow-x: auto;
}

section p {
  margin-top: 1rem;
}

section p + p, section pre {
  margin-top: 2rem;
}

section code {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
  border-radius: .25rem;
  margin-bottom: 1px;
  padding: .125rem .25rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

section a {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity, 1));
}

section a:visited {
  --tw-text-opacity: 1;
  color: rgb(126 34 206 / var(--tw-text-opacity, 1));
}

section a:hover {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity, 1));
}

article .article__meta__link {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity, 1));
  font-weight: 700;
}

mark {
  padding: .25rem .25rem 1px;
}

article ol li {
  list-style-type: decimal;
}

article ol li, article ul li {
  margin-top: 1rem;
  margin-left: 2rem;
  list-style-position: inside;
}

article ul li {
  list-style-type: disc;
}

article ol li p, article ul li p {
  display: inline;
}

article hr {
  margin-top: 1rem;
}

article blockquote {
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
  border-color: rgb(75 85 99 / var(--tw-border-opacity, 1));
  color: rgb(31 41 55 / var(--tw-text-opacity, 1));
  border-left-width: 8px;
  margin-top: 1rem;
  padding: .25rem 1.25rem .75rem;
}

article img {
  margin-top: 1rem;
}

article table {
  table-layout: auto;
  text-align: left;
  width: 100%;
  margin-top: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: block;
  overflow-x: auto;
}

article table thead {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

article table th {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

article table td, article table th {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
  text-align: left;
  padding: .75rem 1rem;
}

.thread__container {
  color: var(--default-color);
}

.hover\:menu__link:hover {
  color: var(--menu-link);
}

.hover\:pagination__link:hover {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity, 1));
}

.hover\:dark-mode-label:hover {
  --tw-text-opacity: 1;
  color: rgb(20 184 166 / var(--tw-text-opacity, 1));
}

article .hover\:header__title__link:hover {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgb(45 212 191 / var(--tw-bg-opacity, 1));
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
  padding: .25rem;
}

article .hover\:article__meta__link:hover {
  --tw-text-opacity: 1;
  color: rgb(13 148 136 / var(--tw-text-opacity, 1));
}

/*# sourceMappingURL=index.css.map */
